import React from 'react'

import { 
    Box,
    Heading,
    Text,
    Center,
} from '@chakra-ui/react'

import NewsletterModal from '../NewsletterSignupBlock/NewsletterModal'

import CtaButton from '../Buttons/CtaButton'

const NewsletterSignUpBlock = ({ slice }) => {
    return (
        <Box bg="steel.400" p={{sm: '6', md: '12'}}>
        <Box textAlign="center">
          <Heading color="white" fontSize="2rem">
          Stay informed on Teton Valley's important projects
          </Heading>
          <Text color="white">
          Get the latest news in your inbox
          </Text>
        </Box>
        
            <Box mt="2">
           <Center>
               <NewsletterModal 
                size="lg"
                colorScheme="sunset"
                btnText="Sign Up"
                modalTitle="Sign Up For Our Newsletter"
               />
           </Center>
       {/* <CtaButton
       size="lg"
       colorScheme="sunset"
       btnText="Sign Up"
     /> */}
     </Box>              
 </Box>
    )
}


export default NewsletterSignUpBlock