import React from "react"
import { Box, Text, Container, Link, Flex, Heading } from "@chakra-ui/react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import HomeBackground from "../components/HomePage/HomeBackground"
import SidebarNav from "../components/SidebarNav/SidebarNav"
import MainContentBox from "../components/MainContentBox/MainContentBox"
import TwentyYearsStrong from "../components/HomePage/TwentyYearsStrong"
import MobileNav from "../components/MobileNav/MobileNav"
import Footer from "../components/Footer/Footer"
import NewsletterBlock from "../components/NewsletterSignupBlock/NewsletterSignupBlock"
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons"

export const query = graphql`
  query PostsQuery($skip: Int!, $limit: Int!) {
    allPrismicBlogPost(
      sort: { fields: data___publish_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            featured_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 60
                    aspectRatio: 1.5
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            post_category
            post_excerpt {
              text
            }
            post_title {
              text
            }
            publish_date(formatString: "MMMM, D YYYY")
          }
        }
      }
    }
  }
`

const News = ({ location, data, pageContext }) => {
  const pageLocation = location.pathname
  // Get pageContext object from gatsby-node.js
  const { numPages, currentPage, postsPerPage } = pageContext
  //console.log(pageContext)
  // console.log(postsPerPage)
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/news" : `/news/${currentPage - 1}`.toString()
  const nextPage = `/news/${currentPage + 1}`.toString()

  if (!data) return null
  const document = data.allPrismicBlogPost.edges
  
  return (
    <>
      <Seo title="Valley Advocates For Responsible Development" />
      <HomeBackground>
        <Container maxW="container.xl">
          <MobileNav />
          <Box paddingTop="20" width="100%">
            <SidebarNav pathName={pageLocation}/>

            <MainContentBox>
              <Box
                mb={{ base: "-9px", md: "-15px" }}
                ml="10px"
                maxW={{ sm: "320px", md: "600px" }}
              >
                <TwentyYearsStrong width="100%" />
              </Box>
              <Box backgroundColor="rgba(255, 255, 255, 0.8)" padding="4">
                <Box>
                  <Heading
                    as="h1"
                    color="red.400"
                    marginBottom="4"
                    fontSize={{ sm: "1.5rem", md: "2rem" }}
                    textAlign="center"
                    paddingTop="4"
                  >
                    The Latest News
                  </Heading>
                </Box>

                <Flex flexWrap="wrap">
                  {document.map(post => {
                    return (
                      <Box
                        w={{ sm: "100%", md: "32%" }}
                        px={{ sm: 0, md: "2" }}
                        mb="8"
                      >
                        <Flex
                          flexDirection="column"
                          justifyContent="space-betweeen"
                          height="100%"
                          flexGrow="1"
                        >
                          <Box mb="1rem">
                            <GatsbyImage
                              image={getImage(
                                post.node.data.featured_image.localFile
                              )}
                            />
                          </Box>
                          <Box>
                            <Heading fontSize="1.25rem" mb="2">
                              {post.node.data.post_title.text}
                            </Heading>
                          </Box>
                          <Box>
                              <Text fontWeight="400" fontSize="0.8rem">{post.node.data.publish_date}</Text>
                            </Box>
                          <Box>
                            <Text>{post.node.data.post_excerpt.text}</Text>
                          </Box>

                          <Box mt="auto">
                            
                            <Link
                              as={GatsbyLink}
                              to={`/news/${post.node.uid}`}
                              variant="blog-link"
                            >
                              Read More <ChevronRightIcon />
                            </Link>
                          </Box>
                        </Flex>
                      </Box>
                    )
                  })}
                </Flex>

                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    listStyle: "none",
                    padding: 0,
                  }}
                >
                  {!isFirst && (
                    <Link
                      as={GatsbyLink}
                      to={prevPage}
                      rel="prev"
                      style={{
                        marginTop: "0.1rem",
                        marginBottom: "0.1rem",
                        padding: "0.5rem",
                      }}
                    >
                      <ChevronLeftIcon />
                      Previous
                    </Link>
                  )}
                  {Array.from({ length: numPages }, (_, i) => (
                    <li
                      key={`pagination-number${i + 1}`}
                      style={{
                        margin: 0,
                      }}
                    >
                      <Link
                        as={GatsbyLink}
                        to={`/news/${i === 0 ? "" : i + 1}`}
                        style={{
                          marginTop: "0.1rem",
                          marginBottom: "0.1rem",
                          padding: "0.5rem",
                          textDecoration: "none",
                          color: i + 1 === currentPage ? "#ffffff" : "",
                          background: i + 1 === currentPage ? "#e27a67" : "",
                        }}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                  {!isLast && (
                    <Link as={GatsbyLink} to={nextPage} rel="next">
                      Next
                      <ChevronRightIcon />
                    </Link>
                  )}
                </ul>
              </Box>

              <Box>
                <NewsletterBlock />
              </Box>
              <Footer />
            </MainContentBox>
          </Box>
        </Container>
      </HomeBackground>
    </>
  )
}

export default News
