import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage} from "gbimage-bridge"

const BackgroundSection = ({ className, children }) => {

  // Prismic query: 
  const data = useStaticQuery(graphql`
  query tetonBackgroundImg {
    allPrismicHomepage {
      edges {
        node {
          data {
            background_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 6000
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`)

  if (!data) return null

  const document = data.allPrismicHomepage.edges[0].node.data;
  const image = getImage(document.background_image.localFile.childImageSharp);
  //const bgImage = convertToBgImage(image);
  

  return (
    <>
          <BgImage image={image} alt={"testimage"} 
            style={{
              width: '100%',
              backgroundPosition: 'top center',
              backgroundAttachment: 'fixed',
              backgroundSize: 'cover'
            }}>
            <div>{children}</div>
          </BgImage>
        </>

  )
}

export default BackgroundSection